/**
 * External Dependencies
 */
import fitty from 'fitty/dist/fitty.min';
import SwiperCore, {
  Swiper,
  Navigation,
  EffectFade,
  Autoplay,
} from 'swiper/core';
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import Isotope from 'isotope-layout';

SwiperCore.use([Swiper, Navigation, EffectFade, Autoplay]);

/**
 * 100vh iOS Workaround
 */
const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

window.addEventListener('load', setVh);
window.addEventListener('resize', setVh);

/**
 * Hamburger Menu
 */
const hamburger = document.querySelector('.hamburger');

hamburger.addEventListener('click', function () {
  this.classList.toggle('active');
  document.body.classList.toggle('nav-open');
  document.querySelector('.nav').classList.toggle('opacity-0');
  document.querySelector('.nav').classList.toggle('is-open');
});

/**
 * Nav Hover Images
 */
const primaryMenu = document.querySelectorAll('.primary-nav__item');

primaryMenu.forEach((menuItem) => {
  let menuImage = document.getElementById(menuItem.dataset.id);

  menuItem.addEventListener('mouseenter', function () {
    let activeImage = document.querySelector('.nav-images__image.is-active');

    if (activeImage) {
      activeImage.classList.remove('is-active');
    }

    if (menuImage) {
      menuImage.classList.add('is-active');
    }
  });
});

/**
 * Parallax Landscape Intro
 */
const scrollEl = document.documentElement;
const root = document.documentElement;

let scrollPos;

function animation() {
  if (scrollPos !== scrollEl.scrollTop) {
    scrollPos = Math.max(0, scrollEl.scrollTop);
    root.style.setProperty('--scrollPos', scrollPos + 'px');
  }

  window.requestAnimationFrame(animation);
}

window.requestAnimationFrame(animation);

/**
 * Fitty JS
 */
fitty('.fit', {
  minSize: 34,
  maxSize: 465,
  multiLine: true,
});

(function () {
  // called when all fonts loaded
  function redrawFitty() {
    document.documentElement.classList.add('fonts-loaded');
    fitty.fitAll();
  }

  // CSS Font Loading API
  function native() {
    // load our custom FoundersGrotesk font
    var fontSans = new FontFace('GT Haptik', 'url(' + fontPath + ')', {
      style: 'normal',
    });
    document.fonts.add(fontSans);
    fontSans.load();

    // if all fonts loaded redraw fitty
    document.fonts.ready.then(redrawFitty);
  }

  // FontFaceObserver
  function fallback() {
    var style = document.createElement('style');
    style.textContent =
      '@font-face { font-family: "GT Haptik"; src: url(' +
      fontPath +
      ') format("woff2");}';
    document.head.appendChild(style);

    var s = document.createElement('script');
    s.src =
      'https://cdnjs.cloudflare.com/ajax/libs/fontfaceobserver/2.1.0/fontfaceobserver.js';
    s.onload = function () {
      new FontFaceObserver('GT Haptik').load().then(redrawFitty);
    };
    document.body.appendChild(s);
  }

  // Does the current browser support the CSS Font Loading API?
  if ('fonts' in document) {
    native();
  } else {
    fallback();
  }
})();

// Fitty Block Parallax
const parallaxElements = document.querySelectorAll('.wp-block-fit-text');

const parallax = (elements) => {
  if ('undefined' !== elements && elements.length > 0) {
    elements.forEach((element) => {
      let y = window.innerHeight - element.getBoundingClientRect().top;
      if (y > 0) {
        element.style.transform = 'translate3d(0, -' + 0.1 * y + 'px ,0)';
      }
    });
  }
};

//If element is in viewport, set its position
parallax(parallaxElements);

//Call the function on scroll
window.onscroll = () => {
  parallax(parallaxElements);
};

/**
 * Offset Grid Slider
 */
const gridSliders = document.querySelectorAll(
  '.wp-block-offset-slider__container'
);

gridSliders.forEach((gridSlider) => {
  const numberOfSlides = gridSlider.querySelectorAll('.swiper-slide').length;
  // eslint-disable-next-line no-unused-vars
  const offsetSlider = new Swiper(gridSlider, {
    loop: numberOfSlides === 1 ? false : true,
    watchOverflow: numberOfSlides === 1 ? true : false,
    navigation: {
      nextEl: '.wp-block-offset-slider__next',
      prevEl: '.wp-block-offset-slider__prev',
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
  });
});

/**
 * Regular Slider Block with Swiper.js & Photoswipe.js
 */
const sliders = document.querySelectorAll('.wp-block-slider__container');
const breakpoint = window.matchMedia('(min-width: 768px)');

sliders.forEach((slider) => {
  let slideshow;
  let hasLightbox = slider.dataset.showLightbox;
  let isLooping = true;
  let slideCount = slider.querySelectorAll('.swiper-slide').length;
  let navPosition = slider.dataset.navPosition;

  var getNextSibling = function (elem, selector) {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
  };

  let nextArrow =
    navPosition === 'centered'
      ? getNextSibling(slider, '.swiper-button-next--centered')
      : slider.querySelector('.swiper-button-next');
  let prevArrow =
    navPosition === 'centered'
      ? getNextSibling(slider, '.swiper-button-prev--centered')
      : slider.querySelector('.swiper-button-prev');

  if (hasLightbox || slideCount === 1) {
    isLooping = false;
  }

  const breakpointCheck = function () {
    if (breakpoint.matches === true) {
      if (slideshow !== undefined) slideshow.destroy(true, true);
      return;
    } else if (breakpoint.matches === false) {
      return enableSwiper();
    }
  };

  const enableSwiper = function () {
    // Swiper init + settings
    slideshow = new Swiper(slider, {
      loop: isLooping,
      watchOverflow: slideCount === 1 ? true : false,
      autoplay: false,
      navigation: {
        nextEl: nextArrow,
        prevEl: prevArrow,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
    });

    // Photoswipe Init + settings
    var initPhotoSwipeFromDOM = function (gallerySelector) {
      // parse slide data (url, title, size ...) from DOM elements
      // (children of gallerySelector)
      var parseThumbnailElements = function (el) {
        var thumbElements = el.childNodes,
          numNodes = thumbElements.length,
          items = [],
          figureEl,
          linkEl,
          size,
          caption,
          description,
          item;

        for (var i = 0; i < numNodes; i++) {
          figureEl = thumbElements[i]; // <figure> element

          // include only element nodes
          if (figureEl.nodeType !== 1) {
            continue;
          }

          linkEl = figureEl.querySelector('a'); // <a> element

          size = linkEl.getAttribute('data-size').split('x');

          // create slide object
          item = {
            src: linkEl.getAttribute('href'),
            w: parseInt(size[0], 10),
            h: parseInt(size[1], 10),
          };

          caption = figureEl.querySelector('.wp-block-slider__caption');
          description = figureEl.querySelector('.wp-block-slider__description');

          if (caption || description) {
            item.title =
              (caption ? caption.outerHTML : '') +
              (description ? description.outerHTML : '');
          }

          if (linkEl.children.length > 0) {
            // <img> thumbnail element, retrieving thumbnail url
            item.msrc = linkEl.getAttribute('href');
          }

          item.el = figureEl; // save link to element for getThumbBoundsFn
          items.push(item);
        }

        return items;
      };

      // triggers when user clicks on thumbnail
      var onThumbnailsClick = function (e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = eTarget.closest('.swiper-slide');
        if (!clickedListItem) {
          return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
          childNodes = clickedListItem.parentNode.childNodes,
          numChildNodes = childNodes.length,
          nodeIndex = 0,
          index;

        for (var i = 0; i < numChildNodes; i++) {
          if (childNodes[i].nodeType !== 1) {
            continue;
          }

          if (childNodes[i] === clickedListItem) {
            index = nodeIndex;
            break;
          }
          nodeIndex++;
        }

        if (index >= 0) {
          // open PhotoSwipe if valid index found
          openPhotoSwipe(index, clickedGallery);
        }
        return false;
      };

      // parse picture index and gallery index from URL (#&pid=1&gid=2)
      var photoswipeParseHash = function () {
        var hash = window.location.hash.substring(1),
          params = {};

        if (hash.length < 5) {
          return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
          if (!vars[i]) {
            continue;
          }
          var pair = vars[i].split('=');
          if (pair.length < 2) {
            continue;
          }
          params[pair[0]] = pair[1];
        }

        if (params.gid) {
          params.gid = parseInt(params.gid, 10);
        }

        return params;
      };

      var openPhotoSwipe = function (
        index,
        galleryElement,
        disableAnimation,
        fromURL
      ) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
          gallery,
          options,
          items;

        items = parseThumbnailElements(galleryElement);
        console.log(items);

        // https://photoswipe.com/documentation/options.html //
        options = {
          /* "showHideOpacity" uncomment this If dimensions of your small thumbnail don't match dimensions of large image */
          showHideOpacity: true,
          closeEl: true,
          captionEl: true,
          fullscreenEl: false,
          preload: true,
          zoomEl: false,
          shareEl: false,
          counterEl: false,
          arrowEl: true,
          preloaderEl: false,
          tapToClose: true,
          // define gallery index (for URL)
          galleryUID: galleryElement.getAttribute('data-pswp-uid'),
          getThumbBoundsFn: function (index) {
            // See Options -> getThumbBoundsFn section of documentation for more info
            var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
              pageYScroll =
                window.pageYOffset || document.documentElement.scrollTop,
              rect = thumbnail.getBoundingClientRect();

            return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
          },
        };

        // PhotoSwipe opened from URL
        if (fromURL) {
          if (options.galleryPIDs) {
            // parse real index when custom PIDs are used
            // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
            for (var j = 0; j < items.length; j++) {
              if (items[j].pid == index) {
                options.index = j;
                break;
              }
            }
          } else {
            // in URL indexes start from 1
            options.index = parseInt(index, 10) - 1;
          }
        } else {
          options.index = parseInt(index, 10);
        }

        // exit if index not found
        if (isNaN(options.index)) {
          return;
        }

        if (disableAnimation) {
          options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe(
          pswpElement,
          PhotoSwipeUI_Default,
          items,
          options
        );
        gallery.init();

        // photoswipe event: Gallery unbinds events
        // (triggers before closing animation)
        gallery.listen('unbindEvents', function () {
          // The index of the current photoswipe slide
          var getCurrentIndex = gallery.getCurrentIndex();
          // Update position of the slider
          slideshow.slideTo(getCurrentIndex, 0, false);
          // 2/2. Start swiper autoplay (on close - if swiper autoplay is true)
          slideshow.autoplay.start();
        });
        // 2/2. Extra Code (Not from photoswipe) - swiper autoplay stop when image in zoom mode (When lightbox is open) */
        gallery.listen('initialZoomIn', function () {
          if (slideshow.autoplay.running) {
            slideshow.autoplay.stop();
          }
        });
      };
      // loop through all gallery elements and bind events
      var galleryElements = document.querySelectorAll(gallerySelector);

      for (var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i + 1);

        if (galleryElements[i].dataset.showLightbox == 'true') {
          galleryElements[i].onclick = onThumbnailsClick;
        }
      }

      // Parse URL and open gallery if it contains #&pid=3&gid=1
      var hashData = photoswipeParseHash();
      if (hashData.pid && hashData.gid) {
        openPhotoSwipe(
          hashData.pid,
          galleryElements[hashData.gid - 1],
          true,
          true
        );
      }
    };

    // execute above function
    initPhotoSwipeFromDOM('.swiper-wrapper');
  };

  if (slider.dataset.destroyDesktop == 'true') {
    breakpoint.addEventListener('change', breakpointCheck);
    breakpointCheck();
  } else {
    enableSwiper();
  }
});

/**
 * Masonry Gallery
 *
 */

let galleryGrid = document.querySelector('.wp-block-gallery__container');

if (galleryGrid) {
  imagesLoaded(galleryGrid, function () {
    // eslint-disable-next-line no-unused-vars
    let galleryMasonry = new Masonry(galleryGrid, {
      columnWidth: '.grid-sizer',
      itemSelector: '.wp-block-gallery__image',
      percentPosition: true,
      horizontalOrder: false,
    });
  });
}

/**
 * Isotope Filter
 */
const filterGrid = document.querySelector('.wp-block-filter__container');
const filterSelect = document.querySelector('.wp-block-filter__select');
let filter;

if (filterGrid) {
  imagesLoaded(filterGrid, function () {
    filter = new Isotope('.wp-block-filter__container', {
      itemSelector: '.wp-block-filter__item',
      layoutMode: 'fitRows',
      hiddenStyle: {
        opacity: 0,
      },
      visibleStyle: {
        opacity: 1,
      },
    });
  });

  filterSelect.addEventListener('change', function () {
    let filterValue = this.value;

    filter.arrange({ filter: filterValue });
  });
}
